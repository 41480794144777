import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'brand',
    name: 'brand.list',
    meta: {
      title: 'Brand List',
      action: 'read',
      subject: CASL.ACCESS.BRAND
    },
    component: () => import('./list/BrandListPage.vue')
  },
  {
    path: 'brand/add',
    name: 'brand.add',
    meta: {
      title: 'Brand Add',
      action: 'create',
      subject: CASL.ACCESS.BRAND
    },
    component: () => import('./form/BrandFormPage.vue')
  },
  {
    path: 'brand/:uuid/edit',
    name: 'brand.edit',
    meta: {
      title: 'Brand Edit',
      action: 'update',
      subject: CASL.ACCESS.BRAND
    },
    component: () => import('./form/BrandFormPage.vue')
  }
]
