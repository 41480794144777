import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'deduct-point',
    name: 'loyalty.deduct-point',
    meta: {
      title: 'Deduct Point List',
      action: 'read',
      subject: CASL.ACCESS.DEDUCT_POINT
    },
    component: () => import('./list/DeductPointListPage.vue')
  },
  {
    path: 'deduct-point/add',
    name: 'loyalty.deduct-point.add',
    meta: {
      title: 'Deduct Point',
      action: 'create',
      subject: CASL.ACCESS.DEDUCT_POINT,
      isDeduct: true
    },
    component: () => import('../add-point/form/AddPointFormPage.vue')
  }
]
