import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'rules',
    name: 'loyalty.rules',
    meta: {
      title: 'Rules',
      action: 'read',
      subject: CASL.ACCESS.RULES
    },
    component: () => import('./list/RulesListPage.vue')
  },
  {
    path: 'rules/point-earning',
    name: 'loyalty.rules.point-earning',
    meta: {
      title: 'Edit Point Earning Rules',
      action: 'update',
      subject: CASL.ACCESS.RULES
    },
    component: () => import('./form/PointEarningFormPage.vue')
  },
  {
    path: 'rules/point-member-registration',
    name: 'loyalty.rules.point-member-registration',
    meta: {
      title: 'Edit Member Registration Rules',
      action: 'update',
      subject: CASL.ACCESS.RULES
    },
    component: () => import('./form/PointMemberRegistrationFormPage.vue')
  },
  {
    path: 'rules/point-redeem',
    name: 'loyalty.rules.point-redeem',
    meta: {
      title: 'Edit Point Redeem Rules',
      action: 'update',
      subject: CASL.ACCESS.RULES
    },
    component: () => import('./form/PointRedeemFormPage.vue')
  }
]
