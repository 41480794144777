import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/login/routes'
import Profile from '@/pages/profile/routes'
import Member from '@/pages/member/routes'
import Brand from '@/pages/brand/routes'
import Outlet from '@/pages/outlet/routes'
import Loyalty from '@/pages/loyalty/routes'
import CRM from '@/pages/crm/routes'
import Merchant from '@/pages/merchant/routes'
import WebContent from '@/pages/web-content/routes'
import Report from '@/pages/report/routes'
import Admin from '@/pages/admin/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        ...Profile,
        ...Member,
        ...Brand,
        Outlet,
        Loyalty,
        CRM,
        ...Merchant,
        WebContent,
        Report,
        Admin
      ]
    },
    {
      path: '/auth',
      component: () => import('@/components/layouts/AuthLayout.vue'),
      children: [...Login]
    },
    { path: '/:pathMatch(.*)*', redirect: '/app/member' }
  ]
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    const selectedProject = import.meta.env.VITE_PROJECT
    const parentTitle =
      selectedProject === '707' ? ' | 707 CMS' : ' | Atmos CMS'

    document.title = (title + parentTitle) as any
  }
  // Continue resolving the route
  next()
})

export default router
