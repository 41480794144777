import MemberList from '@/pages/report/member-list/routes'
import Transaction from '@/pages/report/transaction/routes'
import Voucher from '@/pages/report/voucher/routes'
import Mission from '@/pages/report/mission/routes'

export default {
  path: 'report',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [MemberList, Transaction, Voucher, Mission]
}
