export default [
  {
    path: 'online-store',
    name: 'outlet.online-store',
    meta: {
      title: 'Online Store List',
      action: 'read',
      subject: 'online-store'
    },
    component: () => import('./list/OnlineStoreListPage.vue')
  },
  {
    path: 'online-store/add',
    name: 'outlet.online-store.add',
    meta: {
      title: 'Add Online Store',
      action: 'create',
      subject: 'online-store'
    },
    component: () => import('./form/OnlineStoreFormPage.vue')
  },
  {
    path: 'online-store/:uuid',
    name: 'outlet.online-store.detail',
    meta: {
      title: 'Online Store Detail',
      action: 'read',
      subject: 'online-store'
    },
    component: () => import('./detail/OnlineStoreDetailPage.vue')
  },
  {
    path: 'online-store/:uuid/edit',
    name: 'outlet.online-store.edit',
    meta: {
      title: 'Edit Online Store',
      action: 'update',
      subject: 'online-store'
    },
    component: () => import('./form/OnlineStoreFormPage.vue')
  }
]
