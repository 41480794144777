import FAQ from '@/pages/web-content/faq/routes'
import PrivacyPolicy from '@/pages/web-content/privacy-policy/routes'
import TnC from '@/pages/web-content/tnc/routes'
import Contact from '@/pages/web-content/contact/routes'
import FeaturedMerchant from '@/pages/web-content/featured-merchant/routes'
import FeaturedBrand from '@/pages/web-content/featured-brand/routes'

export default {
  path: 'web-content',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [
    ...FAQ,
    ...PrivacyPolicy,
    ...TnC,
    ...Contact,
    ...FeaturedMerchant,
    ...FeaturedBrand
  ]
}
