import CASL from '@/utils/constants/casl'

export default {
  path: 'transaction',
  name: 'report.transaction',
  meta: {
    title: 'Transaction Report',
    action: 'read',
    subject: CASL.ACCESS.REPORT_TRANSACTION
  },
  component: () => import('./ReportTransactionPage.vue')
}
