import Rules from '@/pages/loyalty/rules/routes'
import AddPoint from '@/pages/loyalty/add-point/routes'
import DeductPoint from '@/pages/loyalty/deduct-point/routes'
import TierConfig from '@/pages/loyalty/tier-config/routes'
import Benefit from '@/pages/loyalty/benefit/routes'

export default {
  path: 'loyalty',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [...Rules, ...AddPoint, ...DeductPoint, ...TierConfig, Benefit]
}
