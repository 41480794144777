import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'contact',
    name: 'web-content.contact',
    meta: {
      title: 'Contact Us',
      action: 'read',
      subject: CASL.ACCESS.CONTACT_US
    },
    component: () => import('./ContactPage.vue')
  }
]
