import CASL from '@/utils/constants/casl'

export default {
  path: 'mission',
  name: 'report.mission',
  meta: {
    title: 'Mission Report',
    action: 'read',
    subject: CASL.ACCESS.REPORT_MISSION
  },
  component: () => import('./ReportMissionPage.vue')
}
