import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'privacy-policy',
    name: 'web-content.privacy-policy',
    meta: {
      title: 'Privacy Policy',
      action: 'read',
      subject: CASL.ACCESS.PRIVACY_POLICY
    },
    component: () => import('./PrivacyPolicyPage.vue')
  }
]
