import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'e-voucher',
    name: 'crm.e-voucher',
    meta: {
      title: 'E-Voucher List',
      action: 'read',
      subject: CASL.ACCESS.E_VOUCHER
    },
    component: () => import('./list/EVoucherListPage.vue')
  },
  {
    path: 'e-voucher/add',
    name: 'crm.e-voucher.add',
    meta: {
      title: 'E-Voucher Add',
      action: 'create',
      subject: CASL.ACCESS.E_VOUCHER
    },
    component: () => import('./form/EVoucherFormPage.vue')
  },
  {
    path: 'e-voucher/:uuid',
    name: 'crm.e-voucher.detail',
    meta: {
      title: 'E-Voucher Detail',
      action: 'read',
      subject: CASL.ACCESS.E_VOUCHER
    },
    component: () => import('./detail/EVoucherDetailPage.vue')
  },
  {
    path: 'e-voucher/:uuid/edit',
    name: 'crm.e-voucher.edit',
    meta: {
      title: 'E-Voucher Edit',
      action: 'update',
      subject: CASL.ACCESS.E_VOUCHER
    },
    component: () => import('./form/EVoucherFormPage.vue')
  }
]
