import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'member',
    name: 'member.list',
    meta: {
      title: 'Member List',
      action: 'read',
      subject: CASL.ACCESS.MEMBER
    },
    component: () => import('./list/MemberListPage.vue')
  },
  {
    path: 'member/edit/:uuid',
    name: 'member.edit',
    meta: {
      title: 'Edit Member',
      action: 'update',
      subject: CASL.ACCESS.MEMBER
    },
    component: () => import('./form/MemberFormPage.vue')
  },
  {
    path: 'member/:uuid',
    component: () => import('./detail/MemberDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'member.detail.account'
        }
      },
      {
        path: 'account',
        name: 'member.detail.account',
        meta: {
          title: 'Member Account',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberAccount.vue')
      },
      {
        path: 'point-history',
        name: 'member.detail.point-history',
        meta: {
          title: 'Member Point History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberPointHistory.vue')
      },
      {
        path: 'transaction-history',
        name: 'member.detail.transaction-history',
        meta: {
          title: 'Member Transaction History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberTransactionHistory.vue')
      },
      {
        path: 'redeem-history',
        name: 'member.detail.redeem-history',
        meta: {
          title: 'Member Redeem History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberRedeemHistory.vue')
      },
      // {
      //   path: 'renewal-history',
      //   name: 'member.detail.renewal-history',
      //   meta: {
      //     title: 'Member Renewal History',
      //     subject: CASL.ACCESS.MEMBER
      //   },
      //   component: () => import('./detail/tabs/MemberRenewalHistory.vue')
      // },
      {
        path: 'mission-history',
        name: 'member.detail.mission-history',
        meta: {
          title: 'Member Mission History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberMissionHistory.vue')
      },
      {
        path: 'changes-log',
        name: 'member.detail.changes-log',
        meta: {
          title: 'Member Changes Log',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberChangesLog.vue')
      }
    ]
  }
]
