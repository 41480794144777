const ACCESS = {
  MEMBER: 'member',

  BRAND: 'brand',

  OUTLET_LIST: 'outlet',
  OUTLET_ACCESS: 'outlet-access',

  RULES: 'loyalty',
  ADD_POINT: 'add-manual-point',
  DEDUCT_POINT: 'deduct-manual-point',
  TIER_CONFIG: 'tier',
  BENEFIT: 'benefit',

  E_VOUCHER: 'e-voucher',
  CAMPAIGN: 'campaign',
  BANNER: 'banner',
  NEWS: 'news-event',
  MISSION: 'mission',

  MERCHANT_LIST: 'merchant',

  FAQ: 'faq',
  PRIVACY_POLICY: 'privacy-policy',
  TNC: 'terms-and-condition',
  CONTACT_US: 'contact-us',
  FEATURED_MERCHANT: 'featured-merchant',
  FEATURED_BRAND: 'featured-brand',

  REPORT_MEMBER_LIST: 'report-member-list',
  REPORT_TRANSACTION: 'report-transaction',
  REPORT_VOUCHER: 'report-voucher',
  REPORT_MISSION: 'report-mission',

  ADMIN: 'admin',
  ROLE: 'role'
}

const MODULES_707 = [
  {
    text: 'Member List',
    childs: [
      {
        name: ACCESS.MEMBER,
        text: 'Member',
        actions: ['read', 'create']
      }
    ]
  },
  {
    text: 'Brand',
    childs: [
      {
        name: ACCESS.BRAND,
        text: 'Brand',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Outlet',
    childs: [
      {
        name: ACCESS.OUTLET_LIST,
        text: 'Outlet List',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.OUTLET_ACCESS,
        text: 'Outlet Access',
        actions: ['read', 'create', 'delete']
      }
    ]
  },
  {
    text: 'Loyalty',
    childs: [
      {
        name: ACCESS.RULES,
        text: 'Rules',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.ADD_POINT,
        text: 'Add Point',
        actions: ['read', 'create']
      },
      {
        name: ACCESS.DEDUCT_POINT,
        text: 'Deduct Point',
        actions: ['read', 'create']
      },
      {
        name: ACCESS.BENEFIT,
        text: 'Membership Benefit',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'CRM',
    childs: [
      {
        name: ACCESS.E_VOUCHER,
        text: 'E-Voucher',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.CAMPAIGN,
        text: 'Campaign',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.BANNER,
        text: 'Banner',
        actions: ['read', 'update', 'delete']
      },
      {
        name: ACCESS.NEWS,
        text: 'News',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.MISSION,
        text: 'Mission',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Merchant',
    childs: [
      {
        name: ACCESS.MERCHANT_LIST,
        text: 'Merchant',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Web Content',
    childs: [
      {
        name: ACCESS.FAQ,
        text: 'FAQ',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.PRIVACY_POLICY,
        text: 'Privacy Policy',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.TNC,
        text: 'Terms and Conditions',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.CONTACT_US,
        text: 'Contact Us',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.FEATURED_MERCHANT,
        text: 'Featured Merchant',
        actions: ['read', 'update', 'delete']
      },
      {
        name: ACCESS.FEATURED_BRAND,
        text: 'Featured Brand',
        actions: ['read', 'update', 'delete']
      }
    ]
  },
  {
    text: 'Admin',
    childs: [
      {
        name: ACCESS.ADMIN,
        text: 'Admin List',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.ROLE,
        text: 'Admin Role',
        actions: ['read', 'create', 'update']
      }
    ]
  }
]

const MODULES_ATMOS = [
  {
    text: 'Member List',
    childs: [
      {
        name: ACCESS.MEMBER,
        text: 'Member',
        actions: ['read', 'create']
      }
    ]
  },
  {
    text: 'Brand',
    childs: [
      {
        name: ACCESS.BRAND,
        text: 'Brand',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Outlet',
    childs: [
      {
        name: ACCESS.OUTLET_LIST,
        text: 'Outlet List',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.OUTLET_ACCESS,
        text: 'Outlet Access',
        actions: ['read', 'create', 'delete']
      }
    ]
  },
  {
    text: 'Loyalty',
    childs: [
      {
        name: ACCESS.RULES,
        text: 'Rules',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.ADD_POINT,
        text: 'Add Point',
        actions: ['read', 'create']
      },
      {
        name: ACCESS.DEDUCT_POINT,
        text: 'Deduct Point',
        actions: ['read', 'create']
      },
      {
        name: ACCESS.TIER_CONFIG,
        text: 'Tier Config',
        actions: ['read', 'update']
      }
    ]
  },
  {
    text: 'CRM',
    childs: [
      {
        name: ACCESS.E_VOUCHER,
        text: 'E-Voucher',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.CAMPAIGN,
        text: 'Campaign',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.BANNER,
        text: 'Banner',
        actions: ['read', 'update', 'delete']
      },
      {
        name: ACCESS.NEWS,
        text: 'News',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.MISSION,
        text: 'Mission',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Merchant',
    childs: [
      {
        name: ACCESS.MERCHANT_LIST,
        text: 'Merchant',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Web Content',
    childs: [
      {
        name: ACCESS.FAQ,
        text: 'FAQ',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.PRIVACY_POLICY,
        text: 'Privacy Policy',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.TNC,
        text: 'Terms and Conditions',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.CONTACT_US,
        text: 'Contact Us',
        actions: ['read', 'update']
      },
      {
        name: ACCESS.FEATURED_MERCHANT,
        text: 'Featured Merchant',
        actions: ['read', 'update', 'delete']
      }
    ]
  },
  {
    text: 'Admin',
    childs: [
      {
        name: ACCESS.ADMIN,
        text: 'Admin List',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.ROLE,
        text: 'Admin Role',
        actions: ['read', 'create', 'update']
      }
    ]
  }
]

export default { ACCESS, MODULES_707, MODULES_ATMOS }
