import CASL from '@/utils/constants/casl'

export default {
  path: 'membership-benefit',
  name: 'loyalty.membership-benefit',
  meta: {
    title: 'Membership Benefit List',
    action: 'read',
    subject: CASL.ACCESS.BENEFIT
  },
  component: () => import('./list/BenefitListPage.vue')
}
