import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'outlet-list',
    name: 'outlet.outlet-list',
    meta: {
      title: 'Outlet List',
      action: 'read',
      subject: CASL.ACCESS.OUTLET_LIST
    },
    component: () => import('./list/OutletListPage.vue')
  },
  {
    path: 'outlet-list/add',
    name: 'outlet.outlet-list.add',
    meta: {
      title: 'Add Outlet List',
      action: 'create',
      subject: CASL.ACCESS.OUTLET_LIST
    },
    component: () => import('./form/OutletListFormPage.vue')
  },
  {
    path: 'outlet-list/:uuid',
    component: () => import('./detail/OutletListDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'outlet.outlet-list.detail.general'
        }
      },
      {
        path: 'general-information',
        name: 'outlet.outlet-list.detail.general',
        meta: {
          title: 'Outlet General Information',
          subject: CASL.ACCESS.OUTLET_LIST
        },
        component: () => import('./detail/tabs/OutletGeneral.vue')
      },
      {
        path: 'operating-hours',
        name: 'outlet.outlet-list.detail.hours',
        meta: {
          title: 'Outlet Operating Hours',
          subject: CASL.ACCESS.OUTLET_LIST
        },
        component: () => import('./detail/tabs/OutletHours.vue')
      },
      {
        path: 'contact-info',
        name: 'outlet.outlet-list.detail.contact',
        meta: {
          title: 'Outlet Contact Info',
          subject: CASL.ACCESS.OUTLET_LIST
        },
        component: () => import('./detail/tabs/OutletContact.vue')
      }
    ]
  },
  {
    path: 'outlet-list/:uuid/general-information/edit',
    name: 'outlet.outlet-list.edit.general',
    meta: {
      title: 'Edit Outlet General Information',
      action: 'update',
      subject: CASL.ACCESS.OUTLET_LIST,
      tab: 'general'
    },
    component: () => import('./form/OutletListFormPage.vue')
  },
  {
    path: 'outlet-list/:uuid/operating-hours/edit',
    name: 'outlet.outlet-list.edit.hours',
    meta: {
      title: 'Edit Outlet Operating Hours',
      action: 'update',
      subject: CASL.ACCESS.OUTLET_LIST,
      tab: 'hour'
    },
    component: () => import('./form/OutletListFormPage.vue')
  },
  {
    path: 'outlet-list/:uuid/contact-info/edit',
    name: 'outlet.outlet-list.edit.contact',
    meta: {
      title: 'Edit Outlet Contact Info',
      action: 'update',
      subject: CASL.ACCESS.OUTLET_LIST,
      tab: 'contact'
    },
    component: () => import('./form/OutletListFormPage.vue')
  }
]
