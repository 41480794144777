import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'faq',
    name: 'web-content.faq',
    meta: {
      title: 'Frequently Asked Questions',
      action: 'read',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./list/FAQListPage.vue')
  }
]
