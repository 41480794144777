import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'merchant',
    name: 'merchant.list',
    meta: {
      title: 'Merchant List',
      action: 'read',
      subject: CASL.ACCESS.MERCHANT_LIST
    },
    component: () => import('./list/MerchantListPage.vue')
  },
  {
    path: 'merchant/add',
    name: 'merchant.add',
    meta: {
      title: 'Merchant Add',
      action: 'create',
      subject: CASL.ACCESS.MERCHANT_LIST
    },
    component: () => import('./form/MerchantFormPage.vue')
  },
  {
    path: 'merchant/:uuid',
    name: 'merchant.detail',
    meta: {
      title: 'Merchant Detail',
      action: 'read',
      subject: CASL.ACCESS.MERCHANT_LIST
    },
    component: () => import('./detail/MerchantDetailPage.vue')
  },
  {
    path: 'merchant/:uuid/edit',
    name: 'merchant.edit',
    meta: {
      title: 'Merchant Edit',
      action: 'update',
      subject: CASL.ACCESS.MERCHANT_LIST
    },
    component: () => import('./form/MerchantFormPage.vue')
  }
]
