import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'outlet-access',
    name: 'outlet.outlet-access',
    meta: {
      title: 'Outlet Access List',
      action: 'read',
      subject: CASL.ACCESS.OUTLET_ACCESS
    },
    component: () => import('./list/OutletAccessPage.vue')
  }
]
