import CASL from '@/utils/constants/casl'

export default {
  path: 'member-list',
  name: 'report.member-list',
  meta: {
    title: 'Member List Report',
    action: 'read',
    subject: CASL.ACCESS.REPORT_MEMBER_LIST
  },
  component: () => import('./ReportMemberListPage.vue')
}
