import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'banner',
    name: 'crm.banner',
    meta: {
      title: 'Home Banner List',
      action: 'read',
      subject: CASL.ACCESS.BANNER
    },
    component: () => import('./list/BannerListPage.vue')
  }
]
