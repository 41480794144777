import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'mission',
    name: 'crm.mission',
    meta: {
      title: 'Mission List',
      action: 'read',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./list/MissionListPage.vue')
  },
  {
    path: 'mission/creator',
    name: 'crm.mission.creator',
    meta: {
      title: 'Mission Creator',
      action: 'read',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./creator/MissionCreatorPage.vue')
  },
  {
    path: 'mission/add',
    name: 'crm.mission.add',
    meta: {
      title: 'Mission Add',
      action: 'create',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./form/mission/MissionFormPage.vue')
  },
  {
    path: 'mission/:uuid',
    component: () => import('./detail/MissionDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'crm.mission.detail'
        }
      },
      {
        path: 'mission',
        name: 'crm.mission.detail',
        meta: {
          title: 'Mission Detail',
          subject: CASL.ACCESS.MISSION
        },
        component: () => import('./detail/tabs/MissionDetail.vue')
      },
      {
        path: 'task',
        name: 'crm.mission.task',
        meta: {
          title: 'Mission Task Detail',
          subject: CASL.ACCESS.MISSION
        },
        component: () => import('./detail/tabs/MissionTask.vue')
      },
      {
        path: 'result',
        component: () => import('./detail/tabs/MissionResult.vue'),
        children: [
          {
            path: '',
            redirect: {
              name: 'crm.mission.result.funnel'
            }
          },
          {
            path: 'mission',
            name: 'crm.mission.result.funnel',
            meta: {
              title: 'Mission Result Funnel',
              subject: CASL.ACCESS.MISSION
            },
            component: () => import('./detail/tabs/result/ResultFunnel.vue')
          },
          {
            path: 'task',
            name: 'crm.mission.result.task',
            meta: {
              title: 'Mission Result Task',
              subject: CASL.ACCESS.MISSION
            },
            component: () => import('./detail/tabs/result/ResultTask.vue')
          }
        ]
      }
    ]
  },
  {
    path: 'mission/creator/:uuid',
    name: 'crm.mission.creator.detail',
    meta: {
      title: 'Mission Creator Detail',
      action: 'update',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./creator/MissionCreatorPage.vue')
  },
  {
    path: 'mission/:uuid/edit',
    name: 'crm.mission.edit',
    meta: {
      title: 'Mission Edit',
      action: 'update',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./form/mission/MissionFormPage.vue')
  },
  {
    path: 'mission/task/:uuid/add',
    name: 'crm.mission.task.add',
    meta: {
      title: 'Mission Task Add',
      action: 'create',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./form/task/TaskFormPage.vue')
  },
  {
    path: 'mission/task/:uuid/edit/:taskUuid',
    name: 'crm.mission.task.edit',
    meta: {
      title: 'Mission Task Edit',
      action: 'update',
      subject: CASL.ACCESS.MISSION
    },
    component: () => import('./form/task/TaskFormPage.vue')
  }
]
