import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'news',
    name: 'crm.news',
    meta: {
      title: 'News List',
      action: 'read',
      subject: CASL.ACCESS.NEWS
    },
    component: () => import('./list/NewsListPage.vue')
  },
  {
    path: 'news/add',
    name: 'crm.news.add',
    meta: {
      title: 'News Add',
      action: 'create',
      subject: CASL.ACCESS.NEWS
    },
    component: () => import('./form/NewsFormPage.vue')
  },
  {
    path: 'news/:uuid',
    name: 'crm.news.detail',
    meta: {
      title: 'News Detail',
      action: 'read',
      subject: CASL.ACCESS.NEWS
    },
    component: () => import('./detail/NewsDetailPage.vue')
  },
  {
    path: 'news/:uuid/edit',
    name: 'crm.news.edit',
    meta: {
      title: 'News Edit',
      action: 'update',
      subject: CASL.ACCESS.NEWS
    },
    component: () => import('./form/NewsFormPage.vue')
  }
]
