import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'campaign',
    name: 'crm.campaign',
    meta: {
      title: 'Campaign List',
      action: 'read',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./list/CampaignListPage.vue')
  },
  {
    path: 'campaign/add',
    name: 'crm.campaign.add',
    meta: {
      title: 'Campaign Add',
      action: 'create',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./form/CampaignFormPage.vue')
  },
  {
    path: 'campaign/:uuid/duplicate',
    name: 'crm.campaign.duplicate',
    meta: {
      title: 'Campaign Duplicate',
      action: 'create',
      subject: CASL.ACCESS.CAMPAIGN,
      isDuplicate: true
    },
    component: () => import('./form/CampaignFormPage.vue')
  },
  {
    path: 'campaign/:uuid',
    name: 'crm.campaign.detail',
    meta: {
      title: 'Campaign Detail',
      action: 'read',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./detail/CampaignDetailPage.vue')
  },
  {
    path: 'campaign/:uuid/edit',
    name: 'crm.campaign.edit',
    meta: {
      title: 'Campaign Edit',
      action: 'update',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./form/CampaignFormPage.vue')
  }
]
