import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'tier-config',
    name: 'loyalty.tier-config',
    meta: {
      title: 'Tier Config',
      action: 'read',
      subject: CASL.ACCESS.TIER_CONFIG
    },
    component: () => import('./list/TierListPage.vue')
  },
  {
    path: 'tier-config/:uuid/edit',
    name: 'loyalty.tier-config.edit',
    meta: {
      title: 'Tier Config Edit',
      action: 'update',
      subject: CASL.ACCESS.TIER_CONFIG
    },
    component: () => import('./form/TierFormPage.vue')
  }
]
