import CASL from '@/utils/constants/casl'

export default {
  path: 'voucher',
  name: 'report.voucher',
  meta: {
    title: 'Voucher Report',
    action: 'read',
    subject: CASL.ACCESS.REPORT_VOUCHER
  },
  component: () => import('./ReportVoucherPage.vue')
}
