import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'featured-merchant',
    name: 'web-content.featured-merchant',
    meta: {
      title: 'Featured Merchant',
      action: 'read',
      subject: CASL.ACCESS.FEATURED_MERCHANT
    },
    component: () => import('./FeaturedMerchantPage.vue')
  }
]
