import EVoucher from '@/pages/crm/e-voucher/routes'
import Campaign from '@/pages/crm/campaign/routes'
import Banner from '@/pages/crm/banner/routes'
import News from '@/pages/crm/news/routes'
import Mission from '@/pages/crm/mission/routes'

export default {
  path: 'crm',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [...EVoucher, ...Campaign, ...Banner, ...News, ...Mission]
}
